/* eslint-disable jsx-a11y/alt-text */
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { query, collection, where, orderBy, getDocs } from "firebase/firestore";
import { useState, useEffect } from "react";
import { db } from "../../utils/firebaseConfig";
import { formatearFecha, getToken, getValueDoc } from "../../utils/utils";
import { useLocation } from "react-router-dom";
import { refresh } from "ionicons/icons";

const SurveyPage: React.FC = () => {
  const name = "Encuestas";
  const [present, dismiss] = useIonLoading();

  const [surveys, setSurveys] = useState([] as any);
  const handleData = () => {
    console.log("handleData");

    const token = getToken();
    if (token.rol === "") return;

    if (navigator.onLine) {
      present({
        message: "Cargando Encuestas",
        spinner: "circles",
      });
      const q = query(
        collection(db, "surveys"),
        where("status", "==", 1),
        orderBy("createdAt", "desc")
      );

      getDocs(q)
        .then((querySnapshot) => {
          dismiss();
          if (!querySnapshot.empty) {
            let resultArray = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setSurveys(resultArray);
            console.log("surveys", resultArray);
          } else {
            setSurveys([]);
            console.log("elseee");
          }
        })
        .catch((error) => {
          console.log("error", error);
          dismiss();
        });
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/surveys") {
      // Coloca aquí el código que quieres ejecutar cada vez que el usuario navegue a "/surveys"
      handleData();
    }
  }, [location]);

  return (
    <div>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>
              <strong>{name}</strong>
            </IonTitle>
            <IonButton
              onClick={() => handleData()}
              slot="end"
              color="primary"
              strong={true}
            >
              <IonIcon
                aria-hidden="true"
                slot="start"
                ios={refresh}
                md={refresh}
              />
              Actualizar
            </IonButton>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          {surveys.map((survey: any, index: any) => {
            return (
              <IonItem key={index}>
                <IonLabel>
                  <IonRow>
                    <IonCol size="9">
                      <p>Encuesta &nbsp;</p>
                      <h2 className="f700">
                        <strong>{getValueDoc(survey.DDE_1, 0)}</strong> (
                        {getValueDoc(survey.DDE_2, 1)}){" "}
                        <strong>{getValueDoc(survey.DDE_4, 3)}</strong>
                      </h2>
                      <p>🗓 {formatearFecha(survey.createdAt)}</p>
                    </IonCol>
                    <IonCol>
                      {/* <IonButton
                        routerLink={"/survey/" + survey.id}
                        color="danger"
                      >
                        <strong>
                          {" "}
                          &nbsp; &nbsp; Eliminar &nbsp; 👀 &nbsp; &nbsp;{" "}
                        </strong>
                      </IonButton> */}

                      <IonButton
                        routerLink={"/survey/" + survey.id}
                        color="secondary"
                      >
                        <strong>
                          {" "}
                          &nbsp; &nbsp; Ver &nbsp; 👀 &nbsp; &nbsp;{" "}
                        </strong>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonLabel>
              </IonItem>
            );
          })}

          <br />
          <br />
          <br />
          {!surveys.length ? (
            <div className="emptyDoodle">
              <div className="doodleImg">
                <img src="assets/empty.png" />
              </div>
              <p>Aún no hay encuestas realizadas.</p>
            </div>
          ) : (
            ""
          )}
        </IonContent>
      </IonPage>
    </div>
  );
};

export default SurveyPage;
