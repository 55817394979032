import { IonPage, IonContent } from "@ionic/react";

const NotFoundPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="centerForm">
          <div className="titleApp">Acceso Denegado</div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NotFoundPage;
