/* eslint-disable jsx-a11y/alt-text */
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { add } from "ionicons/icons";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../utils/firebaseConfig";
import { EventEmmiter } from "../../utils/events.js";
import {
  SAVE_SURVEY,
  formatearFecha,
  getToken,
  getTokenSurveys,
  getValueDoc,
  setTokenSurveys,
} from "../../utils/utils";
import { useEffect, useState } from "react";

const Encuestas: React.FC = () => {
  const name = "Encuestas";
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const [surveys, setSurveys] = useState([] as any);
  const [surveysOffline, setSurveysOffline] = useState([] as any);

  useEffect(() => {
    const handleData = async () => {
      const token = getToken();
      const tokenSurveys = getTokenSurveys();
      setSurveysOffline(tokenSurveys);
      if (token.rol === "") return;

      if (navigator.onLine) {
        present({
          message: "Cargando Encuestas",
          spinner: "circles",
        });
        const q = query(
          collection(db, "surveys"),
          where("userId", "==", token.id),
          where("status", "==", 1),
          orderBy("createdAt", "desc")
        );

        getDocs(q)
          .then((querySnapshot) => {
            dismiss();
            if (!querySnapshot.empty) {
              let resultArray = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));

              setSurveys(resultArray);
              console.log("surveys", resultArray);
            } else {
              setSurveys([]);
              console.log("elseee");
            }
          })
          .catch((error) => {
            console.log("error", error);
            dismiss();
          });
      }
    };

    handleData();
    EventEmmiter.subscribe("UPDATE-ENCUESTAS", (token: any) => {
      handleData();
    });
  }, []);

  const saveSurvey = (survey: any) => {
    if (navigator.onLine) {
      present({
        message: "Guardando",
        spinner: "circles",
      });
      SAVE_SURVEY(survey, () => {
        const tokenSurveys = getTokenSurveys().filter(
          (s) => s.createdAt !== survey.createdAt
        );
        setTokenSurveys(tokenSurveys);
        dismiss();
        presentToast({
          message: "Encuesta guardada con éxito ✅",
          duration: 1500,
          position: "middle",
          cssClass: "toast",
        });
        EventEmmiter.dispatch("UPDATE-ENCUESTAS");
      });
    } else {
      presentToast({
        message: "Revisa tu conexión a internet &nbsp;📶",
        duration: 1500,
        position: "middle",
        cssClass: "toast",
      });
    }
  };

  return (
    <div>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>
              <strong>{name}</strong>
            </IonTitle>
            <IonButton
              routerLink={"/survey/0"}
              onClick={() => {
                EventEmmiter.dispatch("NEW-SURVEY");
              }}
              slot="end"
              color="primary"
              strong={true}
            >
              <IonIcon aria-hidden="true" slot="start" ios={add} md={add} />
              NUEVA ENCUESTA
            </IonButton>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
          {surveysOffline.map((survey: any, index: any) => {
            return (
              <IonItem key={index}>
                <IonLabel>
                  <IonRow>
                    <IonCol size="9">
                      <p>
                        Encuesta offline&nbsp;
                        <small>
                          <strong className="cRed">
                            (<span className="f22">📡</span> &nbsp; SIN ENVIAR)
                          </strong>
                        </small>
                      </p>
                      <h2 className="f700">
                        <strong>{getValueDoc(survey.DDE_1, 0)}</strong> (
                        {getValueDoc(survey.DDE_2, 1)}){" "}
                        <strong>{getValueDoc(survey.DDE_4, 3)}</strong>
                      </h2>
                      <p>🗓 {formatearFecha(survey.createdAt)}</p>
                    </IonCol>
                    <IonCol>
                      <IonButton
                        onClick={() => saveSurvey(survey)}
                        color="success"
                      >
                        <strong>Enviar &nbsp; ☑️</strong>
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonLabel>
              </IonItem>
            );
          })}

          {surveys.map((survey: any, index: any) => {
            return (
              <IonItem key={index}>
                <IonLabel>
                  <IonRow>
                    <IonCol size="9">
                      <p>Encuesta &nbsp;</p>
                      <h2 className="f700">
                        <strong>{getValueDoc(survey.DDE_1, 0)}</strong> (
                        {getValueDoc(survey.DDE_2, 1)}){" "}
                        <strong>{getValueDoc(survey.DDE_4, 3)}</strong>
                      </h2>
                      <p>🗓 {formatearFecha(survey.createdAt)}</p>
                    </IonCol>
                    <IonCol>
                      <IonButton
                        routerLink={"/survey/" + survey.id}
                        color="secondary"
                      >
                        <strong>
                          {" "}
                          &nbsp; &nbsp; Ver &nbsp; 👀 &nbsp; &nbsp;{" "}
                        </strong>
                      </IonButton>

                      {/* <IonButton
                        onClick={() => alert("🚧 En Construcción")}
                        color="primary"
                      >
                        <strong>Editar &nbsp; ✍️</strong>
                      </IonButton>

                      <IonButton
                        onClick={() => alert("🚧 En Construcción")}
                        color="success"
                      >
                        <strong>Enviar &nbsp; ☑️</strong>
                      </IonButton> */}
                    </IonCol>
                  </IonRow>
                </IonLabel>
              </IonItem>
            );
          })}

          <br />
          <br />
          <br />
          {!surveys.length && !surveysOffline.length ? (
            <div className="emptyDoodle">
              <div className="doodleImg">
                <img src="assets/empty.png" />
              </div>
              <p>Aún no tienes encuestas realizadas.</p>
              <IonButton
                routerLink={"/survey/0"}
                onClick={() => {
                  EventEmmiter.dispatch("NEW-SURVEY");
                }}
                slot="end"
                color="primary"
                strong={true}
              >
                <IonIcon aria-hidden="true" slot="start" ios={add} md={add} />
                NUEVA ENCUESTA
              </IonButton>
            </div>
          ) : (
            ""
          )}
        </IonContent>
      </IonPage>
    </div>
  );
};

export default Encuestas;
