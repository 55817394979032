import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  useIonLoading,
} from "@ionic/react";

import { useLocation } from "react-router-dom";
import {
  logOut,
  people,
  person,
  barChartSharp,
  readerSharp,
} from "ionicons/icons";
import "./Menu.css";
import { useState } from "react";
import { EventEmmiter } from "../utils/events.js";
import { roles } from "../utils/constants";
import { getToken, removeToken } from "../utils/utils";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const pollsterMenu: AppPage[] = [
  {
    title: "Encuestas",
    url: "/",
    iosIcon: people,
    mdIcon: people,
  },
  // {
  //   title: "Perfil",
  //   url: "/profile",
  //   iosIcon: person,
  //   mdIcon: person,
  // },
];

const adminMenu: AppPage[] = [
  {
    title: "Inicio",
    url: "/",
    iosIcon: barChartSharp,
    mdIcon: barChartSharp,
  },
  // {
  //   title: "Usuarios",
  //   url: "/users",
  //   iosIcon: people,
  //   mdIcon: people,
  // },
  {
    title: "Encuestas",
    url: "/surveys",
    iosIcon: readerSharp,
    mdIcon: readerSharp,
  },
];

const Menu: React.FC = () => {
  const location = useLocation();
  const tkn = getToken();
  const [present, dismiss] = useIonLoading();

  const [token, setToken] = useState(tkn);
  EventEmmiter.subscribe("UPDATE-ROUTES", (token: any) => {
    setToken(token);
  });

  const logOutSystem = () => {
    removeToken();
    present({
      message: "Cerrando Sesión",
      spinner: "circles",
    });
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>EncuestApp</IonListHeader>
          <IonNote>{token.name}</IonNote>
          <IonList>
            <sub>Menú de Opciones</sub>
          </IonList>
          {token.rol === roles.ADMIN
            ? adminMenu.map((appPage, index) => {
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === appPage.url ? "selected" : ""
                      }
                      routerLink={appPage.url}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <IonIcon
                        aria-hidden="true"
                        slot="start"
                        ios={appPage.iosIcon}
                        md={appPage.mdIcon}
                      />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              })
            : pollsterMenu.map((appPage, index) => {
                return (
                  <IonMenuToggle key={index} autoHide={false}>
                    <IonItem
                      className={
                        location.pathname === appPage.url ? "selected" : ""
                      }
                      routerLink={appPage.url}
                      routerDirection="none"
                      lines="none"
                      detail={false}
                    >
                      <IonIcon
                        aria-hidden="true"
                        slot="start"
                        ios={appPage.iosIcon}
                        md={appPage.mdIcon}
                      />
                      <IonLabel>{appPage.title}</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                );
              })}

          <IonMenuToggle autoHide={false}>
            <IonItem
              className="logout"
              lines="none"
              detail={false}
              onClick={() => logOutSystem()}
            >
              <IonIcon
                aria-hidden="true"
                slot="start"
                ios={logOut}
                md={logOut}
              />
              <IonLabel>Cerrar Sesión</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>

        <IonList id="labels-list">
          <sub>Encuestapp v1.0.0</sub>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
