import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import $ from "jquery";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../utils/firebaseConfig";
import React, { useEffect } from "react";
import { EventEmmiter } from "../utils/events.js";
import {
  closeLogin,
  getToken,
  openLogin,
  setToken,
  solicitarPermisoUbicacion,
} from "../utils/utils";

const LoginPage: React.FC = () => {
  const tkn = getToken();

  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();

  useEffect(() => {
    console.log("mounted");
    if (tkn.rol === "") {
      openLogin();
    }
  });

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    present({
      message: "Cargando",
      spinner: "circles",
    });

    const q = query(
      collection(db, "users"),
      where("user", "==", $("#LOGIN-USER").val()),
      where("password", "==", $("#LOGIN-PASSWORD").val()),
      where("status", "==", 1)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const data = querySnapshot.docs[0].data();
      console.log("querySnapshot.docs", querySnapshot.docs[0].id);
      data.id = querySnapshot.docs[0].id;
      setToken(data);
      presentToast({
        message: "Hola " + data.name,
        duration: 1500,
        position: "middle",
        cssClass: "toast",
      });
      EventEmmiter.dispatch("UPDATE-ROUTES", data);
      EventEmmiter.dispatch("UPDATE-ENCUESTAS");
      closeLogin();
      solicitarPermisoUbicacion();
    } else {
      presentToast({
        message: "Contraseña incorrecta",
        duration: 1500,
        position: "middle",
        cssClass: "toast",
      });
    }
    dismiss();
  };

  return (
    <IonPage id="LOGIN-PAGE">
      <IonContent fullscreen>
        <div className="bg"></div>
        <div className="centerForm">
          <div className="titleApp">EncuestApp</div>
          <p className="txt-center">Iniciar Sesión</p>
          <form onSubmit={handleFormSubmit}>
            <IonItem>
              <IonLabel position="floating">Nombre de usuario</IonLabel>
              <IonInput required type="text" id="LOGIN-USER"></IonInput>
            </IonItem>
            <IonItem>
              <IonLabel position="floating">Contraseña</IonLabel>
              <IonInput required type="password" id="LOGIN-PASSWORD"></IonInput>
            </IonItem>
            <br />
            <IonButton type="submit" expand="block" color="primary">
              <button>Entrar</button>
            </IonButton>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
