import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyA8LNcwB9VWAUcSli97LgkZGonri03lRwY",
  authDomain: "encuestapp-elfuerte.firebaseapp.com",
  projectId: "encuestapp-elfuerte",
  storageBucket: "encuestapp-elfuerte.appspot.com",
  messagingSenderId: "211984090695",
  appId: "1:211984090695:web:26dac4487e318d4e21d362",
  measurementId: "G-7P3FHP38XY",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
