import {
  IonButton,
  IonCol,
  IonContent,
  IonFab,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { close } from "ionicons/icons";
import { useHistory, useParams } from "react-router";
import { questions, surbey } from "../utils/questions";
import $ from "jquery";
import {
  SAVE_SURVEY,
  getToken,
  getTokenSurveys,
  now,
  obtenerLatitudLongitud,
  setTokenSurveys,
  setValue,
} from "../utils/utils";
import { EventEmmiter } from "../utils/events.js";
import { useEffect, useState } from "react";
import { collection, addDoc, getDoc, doc } from "firebase/firestore";
import { db } from "../utils/firebaseConfig";

interface RouteParams {
  id: string;
}

let positions: ({ latitud: number; longitud: number } | null)[] = [];
const ModalEncuesta: React.FC = () => {
  const history = useHistory();
  const [present, dismiss] = useIonLoading();
  const [presentToast] = useIonToast();

  const [response, setResponse] = useState({});
  let surveyResponse = {} as any;

  const handleFormSubmit = async (event: any) => {
    event.preventDefault();
    console.log("surveyResponse", surveyResponse);
    if (!Object.keys(surveyResponse).length) {
      return presentToast({
        message: "Completa los datos del encuestante ⚠️",
        duration: 1500,
        position: "middle",
        cssClass: "toast",
      });
    }

    const token = getToken();

    surveyResponse.userId = token.id;
    surveyResponse.status = 1;
    surveyResponse.positions = positions;
    surveyResponse.createdAt = now();

    const success = () => {
      dismiss();
      presentToast({
        message: "Encuesta guardada con éxito ✅",
        duration: 1500,
        position: "middle",
        cssClass: "toast",
      });
      history.goBack();
      EventEmmiter.dispatch("UPDATE-ENCUESTAS");
    };

    if (navigator.onLine) {
      present({
        message: "Guardando",
        spinner: "circles",
      });
      SAVE_SURVEY(surveyResponse, success);
    } else {
      const tokenSurveys = getTokenSurveys();
      tokenSurveys.push(surveyResponse);
      setTokenSurveys(tokenSurveys);
      success();
    }
  };

  // IF EDIT
  const { id } = useParams<RouteParams>();
  const loadDoc = () => {
    console.log("ID=>>", id);

    present({
      message: "Cargando",
      spinner: "circles",
    });

    const q = doc(db, "surveys", id);
    getDoc(q)
      .then((querySnapshot) => {
        if (querySnapshot.exists()) {
          const data = querySnapshot.data();
          setResponse(data);
        } else {
          setResponse({});
        }
        dismiss();
      })
      .catch((error) => {
        setResponse({});
        console.log("error", error);
        dismiss();
      });
  };

  const saveLocation = () => {
    console.log("saveLocation");

    obtenerLatitudLongitud().then((data) => {
      console.log("data", data);

      positions.push(data);
    });

    // setTimeout(() => {
    //   saveLocation();
    // }, 3000);
  };

  useEffect(() => {
    $(".contentScroll").scrollTop(0);
    if (id !== "0") loadDoc();
    // setInterval(() => {
    //   saveLocation();
    // }, 3000);
    saveLocation();
  }, []);

  return (
    <div>
      <IonPage className="contentScroll">
        <IonHeader>
          <IonToolbar>
            <IonTitle>
              <strong>NUEVA ENCUESTA</strong>
            </IonTitle>
            <IonButton
              onClick={() => history.goBack()}
              slot="end"
              color="danger"
              strong={true}
            >
              <IonIcon aria-hidden="true" slot="start" ios={close} md={close} />
              CERRAR
            </IonButton>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className="contentScroll">
          <form onSubmit={handleFormSubmit}>
            <br />
            <IonLabel>
              <h2 className="txt-center">
                <strong>Datos del Encuestante</strong>
              </h2>
            </IonLabel>
            <br />

            {/* DATOS DEL ENCUESTANTE ================== */}
            {questions.map((q, index) => {
              return (
                <IonItem>
                  <IonLabel className="op1">
                    <span className="f22">{q.icon}</span> &nbsp; {q.question}
                  </IonLabel>
                  <IonSelect
                    interfaceOptions={{
                      header: q.header,
                      subHeader: q.subheader,
                      message: q.message,
                      translucent: true,
                    }}
                    id={q.name}
                    interface="alert"
                    className="op1"
                    placeholder={q.placeholder}
                    okText="Contestar"
                    cancelText="Cerrar"
                    value={setValue(q.name, response)}
                    disabled={"status" in response}
                    onIonChange={(e) => {
                      saveLocation();
                      const pos = $(e.srcElement as any).attr("id") as string;
                      surveyResponse[pos] = e.detail.value;
                    }}
                  >
                    {q.options.map((option, indexOption) => {
                      return (
                        <IonSelectOption key={indexOption} value={option.value}>
                          {option.title}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
              );
            })}

            <br />
            <IonLabel>
              <h2 className="txt-center">
                <strong>Encuesta</strong>
              </h2>
            </IonLabel>
            <br />

            {/* ENCUESTA ================== */}
            {surbey.map((q, index) => {
              return (
                <IonItem>
                  <IonRow>
                    <strong>
                      <br />
                      <span className="f22">Pregunta {q.icon}</span>
                    </strong>{" "}
                    &nbsp; <div className="questionLabel">{q.question}</div>
                    {/* SUB QUESTIONS */}
                    {q.subQuestions
                      ? q.subQuestions.map((_q, indexOption) => {
                          return (
                            <IonRow>
                              <IonItem>
                                <IonLabel className="op1">
                                  {_q.question}
                                </IonLabel>
                                <IonSelect
                                  interfaceOptions={{
                                    header: _q.header,
                                    subHeader: _q.subheader,
                                    message: _q.message,
                                    translucent: true,
                                  }}
                                  interface="alert"
                                  placeholder={_q.placeholder}
                                  okText="Contestar"
                                  cancelText="Cerrar"
                                  value={setValue(_q.name, response)}
                                  className="op1"
                                  disabled={"status" in response}
                                  id={_q.name}
                                  onIonChange={(e) => {
                                    saveLocation();
                                    const pos = $(e.srcElement as any).attr(
                                      "id"
                                    ) as string;
                                    surveyResponse[pos] = e.detail.value;
                                  }}
                                >
                                  {_q.options.map((option, indexOption) => {
                                    return (
                                      <IonSelectOption
                                        key={indexOption}
                                        value={option.value}
                                      >
                                        {option.title}
                                      </IonSelectOption>
                                    );
                                  })}
                                </IonSelect>
                              </IonItem>
                            </IonRow>
                          );
                        })
                      : ""}
                    {/* OPTIONS OF QUESTION */}
                    {q.options ? (
                      <IonSelect
                        interfaceOptions={{
                          header: q.header,
                          subHeader: q.subheader,
                          message: q.message,
                          translucent: true,
                        }}
                        interface="alert"
                        placeholder={q.placeholder}
                        okText="Contestar"
                        cancelText="Cerrar"
                        id={q.name}
                        value={setValue(q.name, response)}
                        className="op1"
                        disabled={"status" in response}
                        onIonChange={(e) => {
                          saveLocation();
                          const pos = $(e.srcElement as any).attr(
                            "id"
                          ) as string;
                          surveyResponse[pos] = e.detail.value;
                        }}
                      >
                        {q.options.map((option, indexOption) => {
                          return (
                            <IonSelectOption
                              key={indexOption}
                              value={option.value}
                            >
                              {option.title}
                            </IonSelectOption>
                          );
                        })}
                      </IonSelect>
                    ) : (
                      ""
                    )}
                  </IonRow>
                </IonItem>
              );
            })}

            <br />

            <IonRow>
              <IonCol></IonCol>
              <IonCol>
                {!("status" in response) ? (
                  <IonButton color="success" size="large" strong={true}>
                    <button>Guardar &nbsp; 💾</button>
                  </IonButton>
                ) : (
                  <IonButton
                    color="danger"
                    size="large"
                    strong={true}
                    onClick={() => history.goBack()}
                  >
                    <IonIcon
                      aria-hidden="true"
                      slot="start"
                      ios={close}
                      md={close}
                    />
                    CERRAR
                  </IonButton>
                )}
              </IonCol>
              <IonCol></IonCol>
            </IonRow>
            <br />
            <br />
            <br />
          </form>
        </IonContent>

        {!("status" in response) ? (
          ""
        ) : (
          <IonFab vertical="bottom" horizontal="center" slot="fixed">
            <div className="ml">MODO LECTURA</div>
          </IonFab>
        )}
      </IonPage>
    </div>
  );
};

export default ModalEncuesta;
