/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { query, collection, where, orderBy, getDocs } from "firebase/firestore";
import { add, document, refresh, refreshCircle } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { db } from "../../utils/firebaseConfig";
import { getResponseGraph, getToken } from "../../utils/utils";
import { questions } from "../../utils/questions";

const HomePage: React.FC = () => {
  const name = "Inicio";
  const [present, dismiss] = useIonLoading();
  const [surveys, setSurveys] = useState([] as any);

  const [totalSurveys, setTotalSurveys] = useState(0);

  const handleData = () => {
    console.log("handleData");

    const token = getToken();
    if (token.rol === "") return;

    if (navigator.onLine) {
      present({
        message: "Cargando Encuestas",
        spinner: "circles",
      });
      const q = query(
        collection(db, "surveys"),
        where("status", "==", 1),
        orderBy("createdAt", "desc")
      );

      getDocs(q)
        .then((querySnapshot) => {
          dismiss();
          if (!querySnapshot.empty) {
            setTotalSurveys(querySnapshot.size);
            let resultArray = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setSurveys(resultArray);
            console.log("surveys", resultArray);
          } else {
            setSurveys([]);
            console.log("elseee");
          }
        })
        .catch((error) => {
          console.log("error", error);
          dismiss();
        });
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/") {
      // Coloca aquí el código que quieres ejecutar cada vez que el usuario navegue a "/surveys"
      handleData();
    }
  }, [location]);

  return (
    <div>
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton />
            </IonButtons>
            <IonTitle>
              <strong>{name}</strong>
            </IonTitle>
            <IonButton
              onClick={() => handleData()}
              slot="end"
              color="primary"
              strong={true}
            >
              <IonIcon
                aria-hidden="true"
                slot="start"
                ios={refresh}
                md={refresh}
              />
              Actualizar
            </IonButton>
          </IonToolbar>
        </IonHeader>

        <IonContent fullscreen className="ion-padding">
          <IonRow>
            <IonCol size="6">
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    <IonIcon aria-hidden="true" ios={document} md={document} />{" "}
                    {totalSurveys}
                  </IonCardTitle>
                  <IonCardSubtitle>Encuestas Realizadas</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
              <IonButton
                routerLink={"/surveys"}
                type="submit"
                expand="block"
                color="secondary"
              >
                Ver Mas
              </IonButton>
            </IonCol>

            {/* <IonCol size="6">
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    <IonIcon aria-hidden="true" ios={person} md={person} /> 3
                  </IonCardTitle>
                  <IonCardSubtitle>Encuestadores</IonCardSubtitle>
                </IonCardHeader>
              </IonCard>
              <IonButton type="submit" expand="block" color="secondary">
                Ver Mas
              </IonButton>
            </IonCol> */}
          </IonRow>

          <br />
          <IonRow>
            <IonCol size="12">
              <IonLabel>
                <h1 className="txt-center">
                  <strong>Reporte</strong>
                </h1>
                <br />
                <h2 className="txt-center">
                  <strong>Datos del Encuestante</strong>
                </h2>
              </IonLabel>

              {/* DATOS DEL ENCUESTANTE ================== */}
              {questions.map((q, index) => {
                return (
                  <div>
                    <IonRow>
                      <IonItem>
                        <IonLabel className="op1">
                          <span className="f22">{q.icon}</span> &nbsp;{" "}
                          {q.question}
                        </IonLabel>
                        <br />
                      </IonItem>
                    </IonRow>
                    {q.options.map((o, index) => {
                      return (
                        <IonRow>
                          <IonItem>
                            <IonLabel>
                              {o.title}:{" "}
                              <strong>{getResponseGraph(q, o, surveys)}</strong>
                            </IonLabel>
                          </IonItem>
                        </IonRow>
                      );
                    })}
                    <br />
                  </div>
                );
              })}

              <br />
            </IonCol>
          </IonRow>
        </IonContent>
      </IonPage>
    </div>
  );
};

export default HomePage;
